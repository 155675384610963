/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import spine from "./swiper";
 *   Alpine.data('swiper', swiper);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="...swiper({
            slidesPerView:1,
            ...etc
            })}" />
 *
 * @returns {{init(): void, el: null, selector: null}}
 */

import Swiper from 'swiper';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import {Pagination, Navigation, Autoplay} from 'swiper/modules';


export default (opts = {}) => ({
    options: {
        modules: [Navigation, Pagination, Autoplay],
        ...opts
    },
    swiper: null,

    init() {
        if (this.swiper !== null) {
            return;
        }
        let el = this.$refs.swiper ?? this.$el;
        this.swiper = new Swiper(el, this.options);

        // if (this.swiper) {
        //     this.buttonStates();
        //     this.swiper.on('slideChange', () => {
        //         this.buttonStates();
        //     });
        // }
    },

    // prev() {
    //     if (this.swiper) {
    //         this.swiper.slidePrev();
    //     }
    // },

    // next() {
    //     if (this.swiper) {
    //         this.swiper.slideNext();
    //     }
    // },


    // buttonStates() {
    //     if (this.swiper && this.$refs.prev && this.$refs.next) {
    //         this.$refs.prev.disabled = this.swiper.isBeginning;
    //         this.$refs.next.disabled = this.swiper.isEnd;
    //     }
    // }

});
