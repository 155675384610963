/**
 * Usage:
 *
 * Step 1: Somewhere in JS...
 *   import Alpine from "alpinejs";
 *   import headroom from "./gallery";
 *   Alpine.data('gallery', gallery);
 *   Alpine.start();
 *
 * Step 2: Somewhere in markup...
 *   <div x-data="gallery()" />
 *
 * @param selector
 * @returns {{init(): void, el: null, selector: null}}
 */

import Glightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

export default (opts = {}) => ({

    options: {
        'width': '1280px',
        'height': '720px',
        'videosWidth': '1200px',
        ...opts
    },

    lightbox: null,
    
    init() {
        this.lightbox = Glightbox(this.options);
    },

});