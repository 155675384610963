export const mobileMenu = () => {

    let root = document.getElementsByTagName( 'html' )[0];
    let backdrop = document.getElementById("backdrop");
    let mainmenu = document.getElementById("mainmenu");
    let menutoggle = document.getElementById("menutoggle");

    let open = false;
    
    if (!mainmenu) {
        return;
    }

    function hideMenu() {
        open = false;
        root.classList.remove('nav-open');
    }

    function showMenu() {
        open = true;
        root.classList.add('nav-open');
    }

    // Close on backdrop click
    backdrop.addEventListener('click', function (event) {

        event.preventDefault();
        hideMenu();

    }, false);

    document.querySelectorAll('#mainmenu li a').forEach(el => {
        el.addEventListener('click', event => hideMenu());
    });

    // Close on resize
    window.addEventListener('resize', function(event) {
        hideMenu();
    }, true);


    // Toggle action
    menutoggle.addEventListener('click', function (event) {

        event.preventDefault();

        open = root.classList.contains("nav-open");

        if (open) {
            hideMenu();
        } else {
            showMenu();
        }

    }, false);


}