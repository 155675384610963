import Swiper from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/autoplay';
import {EffectFade,Autoplay} from 'swiper/modules';

export default () => {

    const productSlides = document.querySelectorAll('#heroswiper .swiper-slide');

    if (!productSlides.length) {
        return;
    }

    const slides = [];

    const heroSwiperOpts = {
        modules: [EffectFade,Autoplay],
        grabCursor: true,
        simulateTouch:true,
        speed: 1500,
        effect: 'fade',
        crossFade: true,
        slidesPerView: 1,
        slidesPerGroup: 1,
        spaceBetween: 0,
        autoplay: {
            delay: 2500,
            stopOnLastSlide: true,
            disableOnInteraction: false,
        },
    };

    const singleProductSwiper = new Swiper('#heroswiper .swiper', heroSwiperOpts);

}