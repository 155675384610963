import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse'
import swiper from "./parts/swiper";
import glightbox from "./parts/glightbox";

Alpine.plugin(collapse);
Alpine.plugin(focus);
Alpine.data("swiper", swiper);
Alpine.data("glightbox", glightbox);

window.Alpine = Alpine;
Alpine.start();