import Choices from 'choices.js';

export const countyChoices = (state='Alabama') => {

    // Bind to the county select element:
    const county_select = document.querySelector('.js-choice');

    // Bail if there is no county select element:
    if (!county_select) {
        return;
    }

    async function getCounties(state) {
        let url = '/actions/_fwutils/counties?state=' + state;
        const response = await fetch(url);
        return await response.json();
    }


    let choices = new Choices(county_select,{
        searchEnabled: true,
        removeItems: true,
        removeItemButton: true,
        duplicateItemsAllowed: false,
        addItems: true,
        placeholder: true,
        placeholderValue: 'Select Counties',
    });

    choices.setChoices(async () => {
        try {
            const response =  await getCounties(state);
            return response.data;
        } catch (err) {
            console.error(err);
        }
    }, 'value', 'label', true);



    // When state_select changes, update the county select:
    const state_select = document.querySelector('#form-input-state');

    if (state_select) {

        state_select.addEventListener('change', async (e) => {
            const selected_state = e.target.value;
            updateCountyChoices(selected_state);
        });

    }


    function updateCountyChoices(state) {

        choices.removeActiveItems();
        choices.clearChoices();
        const getTheChoices = async () => {
            try {
                return await getCounties(state);
            } catch (err) {
                console.error(err);
            }
        }
        getTheChoices().then(r => {

            let selected_state = r.state;
            choices.setChoices(r.data, 'value', 'label', true);

            let search_input = document.querySelector('.choices__input[name="search_terms"]');

            if (search_input) {
                let default_text = 'Select '+ selected_state +' Counties';
                search_input.placeholder = default_text;
                search_input.setAttribute("aria-label",default_text);
            }
            choices.showDropdown(false);


        });


    }

}