// Core Stylesheet
import '../css/style.css';

import heroSwiper from './parts/heroSwiper';
heroSwiper();

import {mobileMenu} from './modules/mobileMenu';
mobileMenu();

import {countyChoices} from './modules/countyChoices';
countyChoices('Alabama');

import './alpine/index';